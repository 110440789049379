.snippet {
  position: relative;
  /* background: #fff; */
  padding: 32px 5%;
  margin: 24px 0;
  /* box-shadow: 0 4px 12px -2px rgba(0, 32, 128, 0.1),
    0 0 0 1px rgba(60, 80, 120, 0.1); */
  border-radius: 16px;
}

.snippet::before {
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 6px;
  padding: 0 8px;
  content: "";
  font-size: 12px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  color: white;
  /* background-color: rgb(255, 25, 100); */
  border-radius: 10px;
  line-height: 20px;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 32px 0;
  margin: 0 -5%;
  overflow: hidden;
}

.dot-bricks {
  position: relative;
  top: 8px;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  /* background-color: #9880ff; */
  color: #9880ff;
  /* box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff,
    10007px 0 0 0 #9880ff; */
  animation: dot-bricks 2s infinite ease;
}

@keyframes dot-bricks {
  0% {
    box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff,
      10007px 0 0 0 #9880ff;
  }
  8.333% {
    box-shadow: 10007px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff,
      10007px 0 0 0 #9880ff;
  }
  16.667% {
    box-shadow: 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff,
      10007px 0 0 0 #9880ff;
  }
  25% {
    box-shadow: 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff,
      9991px 0 0 0 #9880ff;
  }
  33.333% {
    box-shadow: 10007px 0 0 0 #9880ff, 9991px -16px 0 0 #9880ff,
      9991px 0 0 0 #9880ff;
  }
  41.667% {
    box-shadow: 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff,
      9991px 0 0 0 #9880ff;
  }
  50% {
    box-shadow: 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff,
      9991px -16px 0 0 #9880ff;
  }
  58.333% {
    box-shadow: 9991px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff,
      9991px -16px 0 0 #9880ff;
  }
  66.666% {
    box-shadow: 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff,
      9991px -16px 0 0 #9880ff;
  }
  75% {
    box-shadow: 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff,
      10007px -16px 0 0 #9880ff;
  }
  83.333% {
    box-shadow: 9991px -16px 0 0 #9880ff, 10007px 0 0 0 #9880ff,
      10007px -16px 0 0 #9880ff;
  }
  91.667% {
    box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff,
      10007px -16px 0 0 #9880ff;
  }
  100% {
    box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff,
      10007px 0 0 0 #9880ff;
  }
}
