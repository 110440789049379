@tailwind base;
@tailwind components;
@tailwind utilities;

.Toastify {
  z-index: 999999;
  font-size: 14px;
}

.manrope {
  font-family: "Manrope" !important;
}

.ui-btn-hover {
  font-size: 14px;
  cursor: pointer;
  border: none;
  background-size: 300% 100%;
  border-radius: 6px;
  moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.ui-btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.ui-btn-hover:active {
  transform: scale(0.6);
}
