.nav-items {
  background: linear-gradient(90deg, #dc7896 10%, #9e59fe 90%) left/0% 100%
    no-repeat;
  transition: background-size 0.3s linear;
}

.nav-items:hover {
  background-size: 100% 100%;
}

.active-class {
  color: transparent;
  background-clip: text;
  background: linear-gradient(to right, #dc7896, #9e59fe);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
