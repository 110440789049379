.manrope {
  font-family: "Manrope" !important;
}

/* .border-gradient {
  border: 1px solid;
  border-image-slice: 1;
  border-radius: 9999px !important;
} */

.border-gradient {
  position: relative;
  padding: 20px 30px;
  margin: 5px;
  display: inline-block;
  font-size: 30px;
}

.border-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 2px; /* control the border thickness */
  background: linear-gradient(45deg, #dc7896, #9e59fe);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

/* .border-gradient-purple {
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
} */

.zigzag-timeline__item {
  position: relative;

  border-bottom: 2px solid #71717a;

  width: 100%;

  padding-left: 24px;
  padding-right: 24px;
}

.zigzag-timeline__milestone {
  position: absolute;
  top: 50%;

  border-radius: 50%;

  /* background: #aaaaff; */
  text-align: center;
  /* padding: 10px; */
}

/* Styles for even items */
.zigzag-timeline__item:nth-child(2n) {
  border-left: 2px solid #71717a;
}

.zigzag-timeline__item:nth-child(2n) .zigzag-timeline__milestone {
  left: 0;
  transform: translate(-50%, -50%);
}

/* Styles for odd items */
.zigzag-timeline__item:nth-child(2n + 1) {
  border-right: 2px solid #71717a;
}

.zigzag-timeline__item:nth-child(2n + 1) .zigzag-timeline__milestone {
  right: 0;
  transform: translate(50%, -50%);
}

