.bg-home {
  /* background-image: url(../../assets/images/home/imageBackground.png); */
  background: linear-gradient(
    153.44deg,
    #3d2d37 -5.98%,
    #473341 37.15%,
    #3c323e 67.97%,
    #312a36 88.32%,
    #2d2433 98.55%
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */
  min-height: 100vh;
  font-family: "Poppins";
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.decorated::-webkit-scrollbar {
  /* display: none; */
  width: 8px;
}

.decorated::-webkit-scrollbar {
  background-color: white;
  scrollbar-width: thin;
  border-radius: 10px;
  /* scrollbar-color: gray; */
}

.decorated::-webkit-scrollbar-thumb {
  /* display: none; */
  width: 8px;
  background-color: black;
  border: 1px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

/* .decorated::-webkit-scrollbar-thumb:hover {
  background-color: purple;
} */

/* .decorated option {
  background-color: #3d2d37;
  color: white;
  font-size: 12px;
} */

/* .decorated option:checked {
  background-color: white;
  color: black;
} */

.ui-btn-hover.btn-color {
  background-image: linear-gradient(
    to right,
    #9e59fe,
    #dc7896,
    #dc7896,
    #9e59fe
  );
  /* box-shadow: 0px 4px 4px 0 rgba(236, 116, 149, 0.75); */
}
